<template>
    <div>
        <b-row v-if="subscriptions.subscription" class="match-height">
            <b-col cols="12" sm="12" md="6">
                <b-card class="mt-1 text-center h-100 shadow d-flex flex-column" style="border-radius: 12px;" border-variant="primary">
                    <div class="mt-2 h4" style="color: #7367f0; font-weight: bold;">
                        <feather-icon icon="ShoppingBagIcon" size="19" class="mr-2" />
                        Información de suscripción
                    </div>
                    <div class="d-flex justify-content-center mt-3 mb-2">
                        <b-avatar size="lg" rounded class="shadow-sm border">
                            <i class="fas fa-calendar-check fa-3x mb-50"></i>
                        </b-avatar>
                    </div>
                    <div class="mt-2 h4" style="color: #7367f0; font-weight: bold;">
                        {{ subscriptions.subscription.plan.product_name }}
                    </div>

                    <div>
                        <b-badge v-if="subscriptions.subscription.stripe_status == 'active'" variant="success">Activo</b-badge>
                        <b-badge v-else-if="subscriptions.subscription.stripe_status == 'trialing'" variant="warning">Prueba</b-badge>
                        <b-badge v-else variant="danger">Inactivo</b-badge>
                    </div>
                    <div class="mt-1">
                        
                            Vigencia:
                            {{ formatDate(subscriptions.subscription.valid_from) }} - 
                            {{ formatDate(subscriptions.subscription.valid_until) }}
                  
                    </div>

                    <b-alert v-if="subscriptions.subscription.stripe_status != 'active' && subscriptions.subscription.stripe_status != 'trialing'"
                        show variant="danger" class="p-2 rounded" style="margin: 1rem 0;">
                        Alerta: Revise en el portal de facturación sus métodos de pago. No podrá realizar movimientos mientras su suscripción esté inactiva.
                    </b-alert>

                    <b-alert v-if="subscriptions.subscription.cancel_at_period_end"
                        show variant="warning" class="p-2 rounded" style="margin: 1rem 0;">
                        Atención: Su suscripción está marcada como cancelada y terminará el {{ formatDate(subscriptions.subscription.cancel_at) }}
                    </b-alert>

                    <hr>
                    <ul v-if="subscriptions.subscription.plan.description" class="mt-2 pl-3" style="text-align: left">
                        <li v-for="(item, index) in getDescriptionArray()" :key="index">
                            {{ capitalize(item) }}
                        </li>
                    </ul>
                    <hr>
                    
                    <div class="mt-2" style="color: #7367f0; font-weight: bold; font-size: 16px;">
                        ${{ subscriptions.subscription.plan.price }} MXN / {{ subscriptions.subscription.plan.interval }}
                    </div>

                    <div class="mt-auto">
                        <b-button variant="primary" class="mb-2 btn-block" @click="getUrlPortalSession(subscriptions.subscription.stripe_subscription_id)">
                            Portal de facturación
                        </b-button>
                    </div>
                </b-card>
            </b-col>

            <b-col v-for="item in subscriptions.store_plan" :key="item.id" cols="12" sm="12" md="6">
                <b-card class="mt-1 text-center h-100 shadow d-flex flex-column" style="border-radius: 12px" border-variant="primary">
                    <div class="mt-2 h4" style="color: #7367f0; font-weight: bold;">
                        <feather-icon icon="ShoppingBagIcon" size="19" class="mr-2" />
                        Agregar tienda
                    </div>
                    <div class="d-flex justify-content-center mt-3 mb-2">
                        <b-avatar size="lg" rounded class="shadow-sm border">
                            <i class="fas fa-store fa-3x mb-50"></i>
                        </b-avatar>
                    </div>
         
                    <hr>
                    <ul v-if="item.description" class="mt-2 pl-3" style="text-align: left">
                        <li v-for="(descItem, index) in item.description" :key="index">
                            {{ descItem }}
                        </li>
                    </ul>
                    <hr>   
                    
                    <div class="mt-2" style="color: #7367f0; font-weight: bold; font-size: 16px;">
                        ${{ item.price }} MXN / {{ item.interval }}
                    </div>
                    
                    <div
                        class="
                        text-center
                        justify-content-center
                        d-flex
                        flex-column
                        align-items-center
                        mt-3
                        "
                        @click="getUrlCheckoutSession(item.stripe_plan_id)"
                    >
                        <div class="d-flex justify-content-center mb-1 add__icon mt--5">
                        <feather-icon icon="PlusIcon" size="24" />
                        </div>
                    </div>          
                </b-card>
            </b-col>
        </b-row>

        <b-row v-else class="match-height">
            <b-col v-for="item in subscriptions.general_plans" :key="item.id" cols="12" sm="12" md="4">
                <b-card class="mt-1 text-center h-100 shadow d-flex flex-column" style="border-radius: 12px" border-variant="primary">
                    <b-avatar size="lg" rounded class="shadow-sm border">
                        <i class="fas fa-calendar-check fa-3x mb-50"></i>
                    </b-avatar>
                    <div class="mt-2 h4 text-white" style="color: #7367f0 !important; font-weight: bold;">
                        {{ item.product_name }}
                    </div>
                    <hr>
                        <ul v-if="item.description" class="mt-2 pl-3" style="text-align: left">
                            <li v-for="(item, index) in item.description" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                    <hr>           
                    <div class="mt-2" style="color: #7367f0 !important; font-weight: bold; font-size: 16px;">                        
                        ${{ item.price }} MXN {{ item.interval }}
                    </div>
       
                    <div
                        class="
                        text-center
                        justify-content-center
                        d-flex
                        flex-column
                        align-items-center
                        mt-3
                        "
                        @click="getUrlCheckoutSession(item.stripe_plan_id)"
                    >
                        <div class="d-flex justify-content-center mb-1 add__icon mt--5">
                            <feather-icon icon="PlusIcon" size="24" />
                        </div>
                    </div>  
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@axios";
import { formatDate } from "@core/utils/filter";

export default {
    name: "Subscriptions",
    components: {},
    data() {
        return {
            userData: JSON.parse(localStorage.getItem("userData")),
            formatDate,
        };
    },
    beforeMount() {
        this.fetchSubscription(this.$route.params.id)
            .then((res) => {   
                if (!res || (res.stripe_status !== 'active' && res.stripe_status !== 'trialing')) {
                    this.$swal({
                        title: "Atención",
                        text: "No cuentas con una suscripción activa. Ten en cuenta que no podrás registrar ventas en el sistema. Si deseas contratar un plan, dirígete a la sección de planes y selecciona el que mejor se ajuste a las necesidades de tu negocio",
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    }
            })
        this.fetchPlans();
    },    
    methods: {
        ...mapActions("subscriptions", ["fetchPlans", "fetchSubscription"]),

        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        async getUrlCheckoutSession(plan_id) {
            const url = `${process.env.VUE_APP_API_URL}/v1/stripe/checkout_session/${this.$route.params.id}/${plan_id}`;
            const result = await axios.get(url, {
                params: {
                    'checkout_session[quantity]': 1
                }
            });
            window.location.href = result.data.session_url;
            return;
        },
        async getUrlPortalSession(subscription_id) {
            const url = `${process.env.VUE_APP_API_URL}/v1/stripe/billing_portal/${this.$route.params.id}/${subscription_id}`;
            const result = await axios.get(url);
            window.location.href = result.data.portal_session_url;
            return;
        },
        getDescriptionArray() {
            if (typeof this.subscriptions.subscription.plan.description === 'string') {
            return this.subscriptions.subscription.plan.description.split(', ');
            } else {
            return [];
        }
        
  }
    },
    computed: {
        ...mapState(["subscriptions"]),
    },
};
</script>

<style lang="scss" scoped>
.store__card {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;

    // &:hover {
    //   transform: translateY(-5px);
    // }
  }
  .add__icon {
  background-color: rgb(89, 214, 89);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.gutter-md {
    gap: 1rem;
}
</style>
